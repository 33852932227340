import React from 'react';
import { formatTime } from '../../../utils/utilities';
import { UpdateAvailability } from '../UpdateAvailability';
import { typeThroughColor, includedTrainings } from '../../../utils/utilities';

const SideOver = ({ clickEvent, showPopup, hideSideOver, ...props }) => {
  // const info = clickEvent && clickEvent.extendedProps;
  const slots = props.entry && props.entry.attributes.slots;

  return (
    <div style={{ zIndex: 1030 }} className="fixed inset-0 overflow-hidden">
      <div className="absolute inset-0 bg-gray-900 opacity-50"></div>

      <div className="absolute inset-0 overflow-hidden">
        <section className="absolute inset-y-0 pl-16 max-w-full right-0 flex">
          <div className="w-screen max-w-md">
            <div className="h-full flex flex-col bg-white shadow-xl">
              <header className="space-y-1 py-6 bg-white-700">
                <div className="flex items-center px-4 sm:px-6 justify-between space-x-3">
                  <div className="h-7 flex items-center">
                    <button
                      onClick={() => hideSideOver()}
                      aria-label="Close panel"
                      className="text-black font-sf-regular hover:text-gray-600 transition ease-in-out duration-150">
                      <svg
                        className="h-4 w-4"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor">
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              </header>

              <div
                style={{ height: 1, backgroundColor: '#DBDBDB' }}
                className="flex w-11/12 my-2 self-center"
              />

              <div className="bg-white sm:rounded-lg scrolling-auto overflow-scroll">
                <div className="px-4 sm:px-6 flex items-center justify-center pt-4">
                  <h7 className="text-black font-sf-medium text-center">Slot Status</h7>
                </div>

                <div className="px-4 pt-3 sm:px-6">
                  <dl className="flex flex-col">
                    <div className="flex justify-between">
                      <div className="w-1/2">
                        <dt className="text-xs leading-5 font-sf-regular text-gray-500">Slot</dt>
                      </div>

                      <div className="w-1/2 flex justify-end">
                        <dt className="text-xs leading-5 font-sf-regular text-gray-500">Status</dt>
                      </div>
                    </div>

                    {slots && (
                      <div className="mt-2">
                        {Array.isArray(slots) ? (
                          slots.map((slot, index) => (
                            <div key={index} className="flex justify-between">
                              <span className="w-1/2 mt-1 font-sf-regular text-xs leading-5 text-gray-900">
                                {formatTime(slot && slot.from_time)} {' - '}{' '}
                                {formatTime(slot && slot.to_time)}
                              </span>

                              <h2
                                className={`w-20 text-xs font-sf-regular text-white py-1 rounded text-center ${
                                  slot.booked ? 'bg-red-600' : 'bg-green-500 text-black'
                                }`}>{`${slot.booked ? 'Booked' : 'Free slot'}`}</h2>

                              {slot.total_booked && slot.total_booked !== null && (
                                <span className="mt-1 ml-2 font-sf-medium text-xs leading-5 text-gray-900">
                                  {slot && slot.total_booked}
                                  {slot && slot.max_seats && ` / ${slot.max_seats}`}
                                </span>
                              )}
                            </div>
                          ))
                        ) : (
                          <div className="flex justify-between">
                            <span className="w-1/2 mt-1 font-sf-regular text-xs leading-5 text-gray-900">
                              {formatTime(slots.from_time)} {' - '} {formatTime(slots.to_time)}{' '}
                            </span>

                            <h2 className="text-xs font-sf-regular py-1 rounded text-center w-16 bg-green-500 text-white">
                              Free slot
                            </h2>
                          </div>
                        )}
                      </div>
                    )}
                  </dl>
                </div>

                <div className="px-4 sm:px-6 flex flex-col">
                  <small className="text-xs leading-5 font-sf-medium text-gray-500">Type</small>
                  <small className="text-xs leading-5 font-sf-regular text-black">
                    {typeThroughColor(props.entry && props.entry.attributes.entry_color)}
                  </small>
                </div>

                <div className="px-4 py-2 sm:px-6 flex flex-col">
                  <small className="text-xs leading-5 font-sf-medium text-gray-500">
                    Training Options Included
                  </small>

                  <div className="flex">
                    {includedTrainings(
                      props.trainings,
                      props.entry &&
                        props.entry.attributes.availability.data.attributes.selected_trainings
                    ).map((training, index) => (
                      <small key={index} className="text-black pr-2 font-sf-regular">
                        {training.attributes.name}
                      </small>
                    ))}
                  </div>
                </div>

                <UpdateAvailability
                  save={props.save}
                  update={props.save}
                  closeModal={() => {}}
                  selectedDay={props.selectedDay}
                  editEvent={true}
                  info={props.info}
                  buttonLoading={props.buttonLoading}
                  hideSideOver={hideSideOver}
                  passUpdatedEntries={props.passUpdatedEntries}
                  showPopup={showPopup}
                />
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default SideOver;
