import React, { useState, useEffect, useReducer } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, useElements, useStripe } from '@stripe/react-stripe-js';
import './card.scss';
import { UserActions } from '../../../store/actions';
import { connect } from 'react-redux';
import InputMask from 'react-input-mask';
import ReactTooltip from 'react-tooltip';
import { Question } from '../../../assets/img';
import { notify, secureBankField } from '../../../utils/utilities';
import { Loader } from '../Loader';
import { useHistory } from 'react-router-dom';

let connectProps = {
  ...UserActions,
};

let connectState = state => ({
  currentUser: state.User.current.get('currentUser'),
});

let enhancer = connect(connectState, connectProps);

// const CARD_OPTIONS = {
//   iconStyle: 'solid',
//   style: {
//     base: {
//       iconColor: '#c4f0ff',
//       color: '#fff',
//       fontWeight: 500,
//       fontFamily: 'SF-regular',
//       fontSize: '16px',
//     },
//     invalid: {
//       iconColor: '#ffc7ee',
//       color: '#ffc7ee',
//     },
//   },
// };

const Field = ({
  label,
  id,
  type,
  placeholder,
  required,
  autoComplete,
  value,
  onChange,
  disabled,
}) => (
  <div className="FormRow">
    <div className="flex flex-row w-full justify-between items-center">
      <span htmlFor={id} className="FormRowLabel" style={{ fontFamily: 'SF-regular' }}>
        {label}
      </span>
      {id === 'ssn' && (
        <img
          src={Question}
          alt=""
          className="ml-2 h-4 w-4"
          data-tip={'Last 4 digits of SSN number like 1100'}
        />
      )}
    </div>

    <input
      className={`FormRowInput shadow-none form-control ${disabled && 'cursor-not-allowed'}`}
      style={{ fontFamily: 'SF-light' }}
      id={id}
      type={type}
      placeholder={placeholder}
      required={required}
      autoComplete={autoComplete}
      value={value}
      onChange={onChange}
      disabled={disabled}
    />
  </div>
);

const SubmitButton = ({ processing, error, children, disabled }) => (
  <button
    className={`SubmitButton ${error ? 'SubmitButton--error' : ''}`}
    type="submit"
    disabled={processing || disabled}>
    {processing ? 'Processing...' : children}
  </button>
);

const CheckoutForm = ({ props }) => {
  const inititalBillingDetails = {
    email: '',
    phone: '',
    firstName: '',
    lastName: '',
    dob: new Date(),
    ssn: '',
    accountNumber: '',
    routingNumber: '',
    address: '',
  };

  function reducer(state, action) {
    return {
      ...state,
      [action.field]: action.value,
    };
  }

  const [error] = useState(null);
  const [processing, setProcessing] = useState(false);
  const [provider, dispatch] = useReducer(reducer, inititalBillingDetails);
  let currentUser = props.currentUser && props.currentUser.attributes;
  const history = useHistory();

  const stripe = useStripe();
  const elements = useElements();

  useEffect(() => {
    !props.onboarding && props.refreshAthlete();
  }, []);

  const handleSubmit = async event => {
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    if (provider.accountNumber === '' || provider.routingNumber === '' || provider.ssn === '') {
      notify('Please fill all the necessary details.');
      return;
    }

    const payload = {
      athlete: {
        // first_name: provider.firstName,
        // last_name: provider.lastName,
        // dob: moment(provider.dob).format('YYYY-MM-DD'),
        // dob: '1993-12-12',
        // contact_number: provider.phone,
        // address: provider.address,
        account_number: provider.accountNumber,
        routing_number: provider.routingNumber,
        ssn_last_4: provider.ssn,
      },
    };

    setProcessing(true);
    await props.createAccount(payload).then(data => {
      if (data && data.data && data.data.id && props.onboarding) props.pressSoundsGood();
      setProcessing(false);
      !props.onboarding && props.refreshAthlete();
    });
    setProcessing(false);
    resetCardFields();
  };

  const updateConnectAccount = async () => {
    const payload = {
      athlete: {
        account_number: provider.accountNumber
          ? provider.accountNumber
          : currentUser.account_number,
        routing_number: provider.routingNumber
          ? provider.routingNumber
          : currentUser.routing_number,
        ssn_last_4: provider.ssn ? provider.ssn : currentUser.ssn_last_4,
      },
    };

    setProcessing(true);
    await props.updateAccount(payload).then(data => {
      if (data?.data?.id) notify('Updated Successfully', 'success');
      setProcessing(false);
      resetCardFields();
      history.push('/dashboard');
    });
  };

  const resetCardFields = () => {
    dispatch({ field: 'accountNumber', value: '' });
    dispatch({ field: 'ssn', value: '' });
    dispatch({ field: 'routingNumber', value: '' });
  };

  return (
    <div>
      {currentUser && currentUser.account_id ? (
        <div className="w-full bg-white flex items-center justify-center flex-col rounded-lg mt-16 shadow-md p-10">
          <small className="primary-text-regular normal-case text-md">
            Your Bank account was setup successfully!
          </small>

          <div className="flex w-full md:w-full">
            <Field
              label="Full Name"
              id="name"
              type="text"
              required
              autoComplete="name"
              value={`${currentUser.first_name} ${currentUser.last_name}`}
              disabled
            />
          </div>

          <div className="flex w-full md:w-full">
            <Field
              label="Email"
              id="name"
              type="text"
              required
              autoComplete="name"
              value={currentUser.email}
              disabled
            />
          </div>

          <div className="flex w-full md:w-full">
            <Field
              label="Phone Number"
              id="name"
              type="text"
              required
              autoComplete="name"
              value={currentUser.contact_number}
              disabled
            />
          </div>

          <div className="FormRow mt-3">
            <span className="FormRowLabel">Account Number</span>

            <InputMask
              placeholder=""
              className="FormRowInput form-control"
              {...props}
              mask="99999999999"
              maskChar=""
              required
              value={
                provider.accountNumber
                  ? provider.accountNumber
                  : currentUser.account_number
              }
              style={{ outline: 0, fontFamily: 'SF-light' }}
              onChange={e => {
                dispatch({ field: 'accountNumber', value: e.target.value });
              }}
            />
          </div>

          <div className="flex flex-col md:flex-row w-full mt-3">
            <div className="flex flex-col w-full md:w-1/2 mr-2">
              <Field
                label="SSN"
                id="ssn"
                type="number"
                placeholder="Last 4 digits"
                required
                autoComplete="ssn"
                value={provider.ssn ? provider.ssn : currentUser.ssn_last_4}
                onChange={e => {
                  dispatch({ field: 'ssn', value: e.target.value });
                }}
              />
            </div>

            <div className="flex flex-col w-full md:w-1/2">
              <div className="FormRow">
                <div className="flex flex-row w-full justify-between items-center">
                  <span className="FormRowLabel">Routing Number</span>
                  <img
                    src={Question}
                    className="ml-2 h-4 w-4"
                    alt=""
                    data-tip={'Nine digit routing number like 110000000'}
                  />
                </div>

                <InputMask
                  placeholder="Nine digit routing number"
                  className="FormRowInput form-control"
                  {...props}
                  mask="999999999"
                  required
                  maskChar="*"
                  value={
                    provider.routingNumber
                      ? provider.routingNumber
                      : secureBankField(currentUser.routing_number)
                  }
                  style={{ outline: '1px', fontFamily: 'SF-light' }}
                  onChange={e => {
                    dispatch({ field: 'routingNumber', value: e.target.value });
                  }}
                />
              </div>
            </div>
          </div>
          <div className="flex w-full items-end justify-end">
            <button
              className={`primary-dark-button px-4 my-3 ${processing && 'bg-gray-500 '}`}
              disabled={processing}
              onClick={() => {
                updateConnectAccount();
              }}>
              Update
              {processing && (
                <div className="ml-1">
                  <Loader small color="white" />
                </div>
              )}
            </button>
          </div>
        </div>
      ) : (
        <div
          className={`flex bg-white mt-3 p-4 ${props.onboarding ? '' : 'shadow-md'}`}
          style={{ borderRadius: 10 }}>
          <form className="Form w-full" onSubmit={handleSubmit}>
            <fieldset className="FormGroup" id="fieldSet">
              <div className="FormRow mt-3">
                <span className="FormRowLabel">{'Account Number'}</span>

                <InputMask
                  placeholder=""
                  className="FormRowInput form-control"
                  {...props}
                  mask="999999999999"
                  maskChar=" "
                  required
                  value={provider.accountNumber}
                  style={{ outline: 0, fontFamily: 'SF-light' }}
                  onChange={e => {
                    dispatch({ field: 'accountNumber', value: e.target.value });
                  }}
                />
              </div>

              <div className="flex flex-col md:flex-row w-full mt-3">
                <div className="flex flex-col w-full md:w-1/2 mr-2">
                  <Field
                    label="SSN"
                    id="ssn"
                    type="number"
                    placeholder="Last 4 digits"
                    required
                    autoComplete="ssn"
                    value={provider.ssn}
                    onChange={e => {
                      dispatch({ field: 'ssn', value: e.target.value });
                    }}
                  />
                </div>

                <div className="flex flex-col w-full md:w-1/2">
                  <div className="FormRow">
                    <div className="flex flex-row w-full justify-between items-center">
                      <span className="FormRowLabel">Routing Number</span>
                      <img
                        src={Question}
                        className="ml-2 h-4 w-4"
                        alt=""
                        data-tip={'Nine digit routing number like 110000000'}
                      />
                    </div>

                    <InputMask
                      placeholder="Nine digit routing number"
                      className="FormRowInput form-control"
                      {...props}
                      mask="999999999"
                      required
                      maskChar="*"
                      value={provider.routingNumber}
                      style={{ outline: '1px', fontFamily: 'SF-light' }}
                      onChange={e => {
                        dispatch({ field: 'routingNumber', value: e.target.value });
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="FormRow"></div>
            </fieldset>

            <div className="flex w-full items-end justify-end">
              {props.onboarding && (
                <button
                  type="button"
                  onClick={() => props.pressSoundsGood()}
                  className="self-end px-3 underline focus:outline-none rounded py-2 text-primary-color hover:text-blue-500 text-xs inline-flex justify-center">
                  Set up later
                </button>
              )}

              <SubmitButton processing={processing} error={error} disabled={!stripe}>
                Submit
              </SubmitButton>
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

const ELEMENTS_OPTIONS = {
  fonts: [
    {
      cssSrc: 'https://fonts.googleapis.com/css?family=Roboto',
    },
  ],
};

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY_TEST);

const AddCard = props => {
  return (
    <div
      className={`flex min-h-screen overflow-scroll justify-center ${props.onboarding ? 'bg-white' : 'bg-main-background'
        }`}>
      <div className="AppWrapper">
        <ReactTooltip />
        <Elements stripe={stripePromise} options={ELEMENTS_OPTIONS}>
          <CheckoutForm props={props} />
        </Elements>
      </div>
    </div>
  );
};

export default enhancer(AddCard);
