import React, { useState } from 'react';
import DatePicker from 'react-date-picker';
import { InputLabel } from './index';
import moment from 'moment';

function CustomDatePicker({ title, selected, handleOnChange, minDate, disabled }) {
  const [open, setOpen] = useState(false);
  const [_minDate] = useState(minDate);

  return (
    <div className="flex flex-col">
      <InputLabel label={title} />

      <DatePicker
        className="font-sf-regular text-sm rounded form-control"
        calendarClassName=" border-none x-auto"
        placeholderText="Start Date"
        format="MM-dd-yyyy"
        showMonthDropdown
        calendarIcon={null}
        clearIcon={null}
        scrollableYearDropdown
        scrollableMonthYearDropdown
        value={ selected || '' }
        minDate={ _minDate ? _minDate : new Date() }
        maxDate={new Date(moment().add(1, 'Y'))}
        showYearDropdown
        required
        disabled={disabled}
        yearDropdownItemNumber={100}
        onChange={handleOnChange}
        onClick={() => setOpen(true)}
        onCalendarClose={() => setOpen(false)}
        isOpen={open}
      />
    </div>
  );
}

export default CustomDatePicker;
