import React, { useState, useEffect, useRef } from 'react';

let autoComplete;

const loadScript = (url, callback) => {
  let script = document.createElement('script');
  script.type = 'text/javascript';

  if (script.readyState) {
    script.onreadystatechange = function () {
      if (script.readyState === 'loaded' || script.readyState === 'complete') {
        script.onreadystatechange = null;
        callback();
      }
    };
  } else {
    script.onload = () => callback();
  }

  script.src = url;
  document.getElementsByTagName('head')[0].appendChild(script);
};

function handleScriptLoad(updateQuery, setParseSearch, autoCompleteRef, setLoc) {
  autoComplete = new window.google.maps.places.Autocomplete(autoCompleteRef.current, {
    type: ['address'],
    componentRestrictions: { country: ['us', 'ca'] },
  });
  // autoComplete.setFields(['address_components', 'formatted_address']);
  autoComplete.addListener('place_changed', item => {
    handlePlaceSelect(updateQuery, setParseSearch, setLoc);
  });
}

async function handlePlaceSelect(updateQuery, setParseSearch, setLoc) {
  const addressObject = autoComplete.getPlace();
  const query = addressObject && addressObject.formatted_address;
  updateQuery(query);
  setLoc(addressObject && addressObject.formatted_address);

  var address = addressObject && addressObject.address_components;
  var city = '';
  var state = '';
  var zip = '';
  var street = '';
  address &&
    address.forEach(function (component) {
      var types = component.types;

      if (types.indexOf('street_number') > -1) {
        street = component.long_name;
      }

      if (types.indexOf('locality') > -1) {
        city = component.long_name;
      }

      if (types.indexOf('administrative_area_level_1') > -1) {
        state = component.short_name;
      }

      if (types.indexOf('postal_code') > -1) {
        zip = component.long_name;
      }
    });

  let data = { city, state, zip, street };
  setParseSearch(data);
}

function SearchLocationInput(props) {
  const [query, setQuery] = useState('');
  const autoCompleteRef = useRef(null);

  useEffect(() => {
    setQuery();

    if (window?.google?.maps) {
      handleScriptLoad(setQuery, props.setParseSearch, autoCompleteRef, props.setLoc);
      return;
    }

    loadScript(
      `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAP_KEY}&libraries=places`,
      () => handleScriptLoad(setQuery, props.setParseSearch, autoCompleteRef, props.setLoc)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={`search-location-input w-full ${'gray-border-right'}`}>
      <input
        className={`font-sf-Regular text-xs  text-black rounded form-control ${
          props.lightTheme ? 'bg-white' : 'bg-transparent'
        }`}
        ref={autoCompleteRef}
        onKeyPress={e => {
          e.key === 'Enter' && e.preventDefault();
        }}
        onChange={event => {
          props.setLoc(event.target.value);
          setQuery(event.target.value);
        }}
        placeholder={props.placeholder ? props.placeholder : 'Address'}
        value={query ? query : props.value}
        required={props.required}
      />
    </div>
  );
}

export default SearchLocationInput;
