import React, { Component } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { CFormGroup } from '@coreui/react';
import Loader from 'react-loader-spinner';
import NumberFormat from 'react-number-format';
import { DurationPicker } from '../Athlete/DurationPicker';
import Select from 'react-select';
import { SelectionButton, InputLabel, CustomDatePicker } from './index';
import {
  availableSessionsCountOptions,
  availableSlots,
  GENDER_OPTIONS,
  GradeOptions,
  GradesValues,
  privacySettings,
  trainingOption
} from '../../constants';
import ServiceLocation from '../Athlete/Services/ServiceLocation';
import { notify, removeSpecialCharacters } from '../../utils/utilities';
import SelectSearch from "react-select-search";
import TrayvoToggle from "./TrayvoToggle";
import moment from "moment";

const initialStates = {
  editVisible: false,
  serviceObj: null,
  duration: 'Min',
  minDuration: null,
  cusDuration: null,
  link: '',
  price: '',
  desc: '',
  package: false,
  package_start: null,
  package_end: null,
  sessions_count: null,
  trainingImage: '',
  connectionDetails: '',
  selectedTraining: null,
  trainingImageFile: null,
  privacySetting: privacySettings[0],
  locations: [{ id: 1, location: '', address: '' }],
  eventName: '',
  maxSeats: availableSlots[0],
  gender: null,
  minGrade: 'unset',
  maxGrade: 'unset',
  trainingType: trainingOption.INDIVIDUAL,
  invalidGrades: false,
  packageDescriptionChecked: false,
  displayPackageDescriptionReminder: false
};


function PackageDescriptionConfirmationModal({ onConfirm }) {
  return (
    <Modal
      show={true}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Package Details
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ padding: 0, maxHeight: '60vh', overflow: 'auto' }}>
        <div className="flex  justify-center m-5">
          <h4>Please make sure you included specific dates/times for each session of this package in your description</h4>
        </div>

      </Modal.Body>
      <Modal.Footer className="flex">
        <Button className="flex justify-center"
          type="button"
          onClick={onConfirm}>
          OK
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

class CreateTraining extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...initialStates,
    };
  }

  componentDidMount() {
    this.setState({ locations: [{ id: 1, location: '', address: '' }] });
    const { onboarding } = this.props;
    if (onboarding) {
      this.getOnBoardingData();
    }
  }

  async getOnBoardingData() {
    const { getProvidedServices } = this.props;
    await getProvidedServices();
  }
  addLocation = () => {
    let { locations } = this.state;
    this.setState({
      locations: [...locations, { id: locations.length + 1, location: '', address: '' }],
    });
  };

  removeLocation = id => {
    let { locations } = this.state;
    let removeLocations = locations.filter(item => item.id !== id);

    this.setState({
      locations: removeLocations,
      [`location${id}Characters`]: 0
    });
  };

  onUpdateLocations = (id, tag, value) => {
    let { locations } = this.state;
    let updatedLocations = locations.map(item => {
      if (item.id === id) item[tag] = value;
      return item;
    });
    this.setState({ locations: updatedLocations });
  };

  onMaxGradeChanged = (option) => {
    const { minGrade } = this.state;

    this.setState({
      maxGrade: option,
      invalidGrades: this.gradesInvalid(minGrade, option)
    });
  }

  onMinGradeChanged = (option) => {
    const { maxGrade } = this.state;

    this.setState({
      minGrade: option,
      invalidGrades: this.gradesInvalid(option, maxGrade)
    });
  }

  gradesInvalid = (min, max) => {
    if (min && max) {
      const minVal = GradesValues[min];
      const maxVal = GradesValues[max];

      return maxVal < minVal;
    }

    return false;
  }

  allGradesToggle = (allowAll) => {
    if (allowAll) {
      this.setState({
        minGrade: null,
        maxGrade: null,
        invalidGrades: false
      });
    }
  }

  async submit(e) {
    e.preventDefault();
    const {
      onboarding,
      currentUser,
      changeButtonLoading,
      getTrainingSignedUrl,
      uploadImage,
      updateProvidedService,
      getProvidedServices,
      changeContent,
      createService,
      setDefaultTrainingType,
      changeTrainingNameOB,
    } = this.props;

    let {
      trainingImage,
      trainingImageFile,
      link,
      cusDuration,
      minDuration,
      locations,
      connectionDetails,
      eventName,
      privacySetting,
      maxSeats,
      gender,
      minGrade,
      maxGrade,
      trainingType,
      price,
      desc,
      package_start,
      package_end,
      sessions_count
    } = this.state;

    if (!cusDuration && !minDuration) {
      notify('Select a duration.');
      return;
    }

    if (privacySetting === null) {
      notify('Select privacy setting.');
      return;
    }

    if (!gender) {
      notify('Please select gender');
      return;
    }

    const gradesNotSelected = minGrade === 'unset' || maxGrade === 'unset';
    const notFullRangeDefined = (!minGrade && maxGrade) || (minGrade && !maxGrade);
    if (gradesNotSelected || notFullRangeDefined) {
      notify('Please select grade range');
      return;
    }

    if (this.state.package) {
      if (!package_start || !package_end) {
        notify('Please select when the package starts and when it ends');
        return;
      }
      if (package_start.getTime() > package_end.getTime()) {
        notify('Package start and end dates are incorrect');
        return;
      }
      if (!sessions_count) {
        notify('Please select sessions count in the package');
        return;
      }

      if (!this.state.packageDescriptionChecked) {
        this.setState({
          packageDescriptionChecked: true,
          displayPackageDescriptionReminder: true
        });
        return;
      }
    }

    changeButtonLoading(true);

    let payload = {
      provided_service: {
        discription: desc,
        package: this.state.package,
        package_start: null,
        package_end: null,
        sessions_count: null,
        status: 1,
        slug_title: `${currentUser && currentUser.attributes.slug}/${removeSpecialCharacters(
          link
        )}`,
        price,
        name: eventName,
        duration: parseInt(cusDuration ? cusDuration : minDuration),
        gender,
        min_grade: minGrade,
        max_grade: maxGrade,
        training_type:
          trainingType === trainingOption.VIRTUAL
            ? 1
            : trainingType === trainingOption.INDIVIDUAL
              ? 2
              : 3,
        privacy_setting: privacySetting.value,
        provided_service_locations_attributes: locations.map(item => ({
          location: item.location,
          address: item.address,
        })),
      },
    };

    if (this.state.package) {
      payload.provided_service.package_start = moment(this.state.package_start).format('YYYY-MM-DD');
      payload.provided_service.package_end = moment(this.state.package_end).format('YYYY-MM-DD');
      payload.provided_service.sessions_count = this.state.sessions_count.value;
    }

    if (trainingType === trainingOption.VIRTUAL) {
      payload.provided_service.connection_detail = connectionDetails;
    }

    if (trainingType === trainingOption.GROUP || trainingType === trainingOption.VIRTUAL) {
      payload.provided_service.max_seats = maxSeats.value;
    }

    if (trainingImage && trainingImageFile) {
      const image = await getTrainingSignedUrl();
      await uploadImage(trainingImageFile, image.signed_url);
      payload.provided_service.image_url = image.url;
    }

    setDefaultTrainingType(trainingType);
    if (onboarding) {
      let providedService = this.findProvidedService(trainingType);
      if (trainingType !== 'Virtual') {
        let locations = providedService.attributes.provided_service_locations.data.map(item => ({
          id: item.id,
          _destroy: true,
        }));
        locations = locations.concat(
          this.state.locations.map(item => ({
            location: item.location,
            address: item.address,
          }))
        );
        payload.provided_service.provided_service_locations_attributes = locations;
      }

      await updateProvidedService(providedService.id, payload);
      await getProvidedServices();
      changeButtonLoading(false);
      changeContent();
      changeTrainingNameOB(eventName);
    } else {
      await createService(payload).then(async response => {
        if (response) {
          await getProvidedServices(1, 100);
          this.props.setSelectedTraining({
            trainingType: response.data.attributes.training_type,
            trainingId: response.data.attributes.id,
          });
          this.closePopup(true);
        }
      });
      changeButtonLoading(false);
    }
  }

  findProvidedService = trainingType => {
    let providedService = this.props.providedServices.filter(
      item => item.attributes.training_type === trainingType.toLowerCase()
    );
    return (providedService && providedService[0]) || null;
  };

  onChange = (tag, value) => {
    this.setState({ [tag]: value });
  };

  onCharacterInsert = (name, value) => {
    this.setState({ [`${name}Characters`]: value.length })
  }

  closePopup = (showAvailabilityPopup = false) => {
    let { closePopup } = this.props;

    showAvailabilityPopup = showAvailabilityPopup && !this.state.package

    this.setState({ initialStates }, () => {
      closePopup(showAvailabilityPopup && { showAvailabilityPopup: true });
    });
  };

  render() {
    const { buttonLoading, currentUser, onboarding } = this.props;

    let {
      minDuration,
      cusDuration,
      price,
      link,
      desc,
      locations,
      eventName,
      privacySetting,
      connectionDetails,
      maxSeats,
      minGrade,
      maxGrade,
      trainingType,
      package_start,
      package_end,
      sessions_count,
      displayPackageDescriptionReminder

    } = this.state;
    const isPackage = this.state.package;

    const allGrades = minGrade === null && maxGrade === null;

    return (
      <div className="bg-white rounded-lg w-full">
        <div className={`flex flex-col px-4 relative ${onboarding ? 'py-1' : 'py-4'}`}>
          {!onboarding && (
            <div className="self-center">
              <small className="text-sm text-primary-color font-sf-medium">
                Create Training Option
              </small>
            </div>
          )}

          {!onboarding && (
            <button
              type="button"
              onClick={() => this.closePopup()}
              className="absolute top-0 right-0 p-4 rounded-lg focus:outline-none self-end">
              <small className="hover:text-blue-500 text-primary-color font-medium text-lg">
                X
              </small>
            </button>
          )}
        </div>

        <div className="flex py-1 flex-col bg-extra-gray rounded-md">
          <form
            className="w-full"
            onSubmit={e => {
              this.submit(e);
            }}>
            <div className="px-10">
              <InputLabel label="Type" />
              <div className="flex justify-between w-full">
                <div className="w-1/3">
                  <SelectionButton
                    text={trainingOption.INDIVIDUAL}
                    checked={trainingType === trainingOption.INDIVIDUAL}
                    value={trainingOption.INDIVIDUAL}
                    onClick={() => {
                      this.setState({ trainingType: trainingOption.INDIVIDUAL });
                    }}
                  />
                </div>

                <div className="w-1/3 px-2">
                  <SelectionButton
                    text={trainingOption.GROUP}
                    checked={trainingType === trainingOption.GROUP}
                    value={trainingOption.GROUP}
                    onClick={() => {
                      this.setState({ trainingType: trainingOption.GROUP });
                    }}
                  />
                </div>

                <div className="w-1/3">
                  <SelectionButton
                    text={trainingOption.VIRTUAL}
                    checked={trainingType === trainingOption.VIRTUAL}
                    value={trainingOption.VIRTUAL}
                    onClick={() => {
                      this.setState({ trainingType: trainingOption.VIRTUAL });
                    }}
                  />
                </div>
              </div>


              { /**
               PACKAGE
               */ }

              <div className="my-3">
                <div className="flex justify-between">
                  <InputLabel className="md:w-1/2"
                    color={isPackage ? '#000' : null}
                    label="Package" />

                  <div className="inline-flex md:w-auto pr-3">
                    <TrayvoToggle
                      value={isPackage}
                      id={0}
                      onChange={(newValue) => {
                        this.setState({
                          package: newValue
                        });
                      }}
                    />
                  </div>
                </div>

                <div className='my-2 text-justify text-xs' style={{ color: isPackage ? '#000' : '#ccc' }}>
                  Package options are used when offering your customer multiple training sessions for a set price, i.e. 5 training sessions for $200
                </div>

                {
                  isPackage &&
                  <div className="flex flex-col justify-start content-start">
                    <div className="flex justify-between flex-wrap">
                      <div className="w-full md:w-1/2 pr-2">
                        <CustomDatePicker
                          title="Package Start Date"
                          placeholder="First training date"
                          handleOnChange={(date) => this.setState({ package_start: date })}
                          selected={package_start || ''}
                        />
                      </div>

                      <div className="w-full md:w-1/2 pr-2">
                        <CustomDatePicker
                          title="Package End Date"
                          placeholder="Last training date"
                          handleOnChange={(date) => this.setState({ package_end: date })}
                          selected={package_end}
                        />
                      </div>
                    </div>
                    <div className='my-2 text-justify text-xs' style={{ color: isPackage ? '#000' : '#ccc' }}>
                      Enter date range (first training date-last training date) of your package for customers to see. You can display specific dates/times in the description below.
                    </div>

                    <div className="flex flex-col">
                      <InputLabel label="Sessions" />
                      <div className="text-center w-full">
                        <Select
                          className="border-t-0 border-l-0 border-r-0 font-sf-regular text-xs text-black "
                          placeholder="Sessions count"
                          isSearchable={false}
                          required
                          value={sessions_count}
                          onChange={option => this.onChange('sessions_count', option)}
                          options={availableSessionsCountOptions}
                        />
                      </div>
                    </div>

                    <div className='my-2 text-justify text-xs' style={{ color: '#000' }}>
                      Enter the # of sessions included in this package for customers to see
                    </div>
                  </div>
                }
              </div>

              <DurationPicker
                minDuration={minDuration}
                cusDuration={cusDuration}
                setCusDuration={cusDuration => this.setState({ cusDuration })}
                setMinDuration={minDuration => this.setState({ minDuration })}
              />

              <div className="flex justify-between">
                <div className="w-1/2 pr-3">
                  <InputLabel label={`Training Name (${eventName.length || 0}/35 characters)`} color={eventName.length === 35 && 'red'} />
                  <Form.Control
                    maxLength="35"
                    minLength="5"
                    type="text"
                    id="custom-group"
                    placeholder="i.e. Offensive Training"
                    className="font-sf-regular bg-transparent text-black text-xs mr-2"
                    onChange={e => {
                      this.onChange('eventName', e.target.value);
                      this.onChange(
                        'link',
                        e.target.value.toLowerCase().replace(/\s/g, '').replace(/-/g, '')
                      );
                    }}
                    value={ eventName || '' }
                    required
                  />
                </div>

                <div className="w-1/2">
                  <InputLabel label="Cost" />
                  <CFormGroup id="custom-group">
                    <div className="text-left">
                      <NumberFormat
                        value={price}
                        thousandSeparator={true}
                        placeholder="$ 0.00"
                        allowNegative={false}
                        className="font-sf-regular bg-transparent text-black text-xs rounded form-control"
                        prefix={'$ '}
                        onValueChange={({ value }) => {
                          this.onChange('price', value);
                        }}
                      />
                    </div>
                  </CFormGroup>
                </div>
              </div>

              <Form.Group>
                {trainingType !== trainingOption.VIRTUAL && (
                  <div className={`flex flex-col`} key={locations.length}>
                    {locations.map((item, index) => (
                      <ServiceLocation
                        hideRemove={index === 0}
                        id={item.id}
                        withBorder={locations.length > 1}
                        locationValue={item.location}
                        addressValue={item.address}
                        key={index}
                        totalCharacters={item.location.length ? item.location.length : 0}
                        onUpdateLocations={(id, tag, value) => {
                          this.onUpdateLocations(id, tag, value)
                        }}
                        removeLocation={id => {
                          this.removeLocation(id)

                        }}
                      />
                    ))}

                    <div className="my-1 cursor-pointer" onClick={() => this.addLocation()}>
                      <span className="text-blue-500">+Add Another Location</span>
                    </div>
                  </div>
                )}

                {trainingType === trainingOption.VIRTUAL && (
                  <div>
                    <InputLabel label="Connection details" />
                    <Form.Control
                      type="text"
                      placeholder="Enter Zoom, Google Meet, or other link"
                      className="font-sf-regular text-black text-xs"
                      onChange={e => {
                        this.onChange('connectionDetails', e.target.value);
                      }}
                      value={connectionDetails}
                      required
                    />
                  </div>
                )}

                {(trainingType === trainingOption.VIRTUAL || trainingType === trainingOption.GROUP) && (
                  <div className="flex flex-col">
                    <InputLabel label="Available Spots" />
                    <div className="text-center w-full">
                      <Select
                        components={{
                          IndicatorSeparator: () => null,
                        }}
                        className="border-t-0 border-l-0 border-r-0 font-sf-regular text-xs text-black "
                        placeholder="Available spots"
                        isSearchable={false}
                        required
                        value={maxSeats}
                        onChange={option => this.onChange('maxSeats', option)}
                        options={availableSlots}
                      />
                    </div>
                  </div>
                )}

              </Form.Group>

              <Form.Group>
                <div className="flex flex-col">
                  <InputLabel label="Gender" />
                  <div className="text-center w-full">
                    <SelectSearch
                      options={GENDER_OPTIONS}
                      onChange={option => this.onChange('gender', option)}
                      placeholder="Select gender"
                    />
                  </div>
                </div>
              </Form.Group>

              <Form.Group>
                <div className="flex justify-between">
                  <InputLabel label="Ages (i.e. Grades 9-12)" />

                  <label className="inline-flex items-center">
                    <span className="primary-text-regular normal-case">All ages</span>
                    <input
                      type="checkbox"
                      checked={allGrades}
                      onChange={(event) => this.allGradesToggle(event.target.checked)}
                      className="form-checkbox h-3 w-3 ml-1"
                    />
                  </label>
                </div>

                <div className="flex justify-between">
                  <div className="w-1/2 pr-1">
                    <InputLabel label="Minimum Grade" />
                    <div className="text-center w-full max-results-height-250">
                      <SelectSearch
                        options={GradeOptions}
                        onChange={option => this.onMinGradeChanged(option)}
                        value={minGrade}
                        placeholder="Select Min Grade"
                      />
                    </div>
                  </div>
                  <div className="w-1/2 pr-1">
                    <InputLabel label="Maximum Grade" />
                    <div className="text-center w-full max-results-height-250">
                      <SelectSearch
                        options={GradeOptions}
                        onChange={option => this.onMaxGradeChanged(option)}
                        value={maxGrade}
                        placeholder="Select Max Grade"
                      />
                    </div>
                  </div>
                </div>
                <div className={'text-center'}>
                  <span className={`ml-2 text-xs text-red-800 transition-opacity duration-300 ${this.state.invalidGrades ? 'opacity-100' : 'opacity-0'}`}>
                    Min Grade has to be equal to or less than Max Grade
                  </span>
                </div>

              </Form.Group>


              <CFormGroup>
                <InputLabel label={`Description (${desc.length || 0}/200 characters)`} color={desc.length === 160 && 'red'} />
                <textarea
                  className="text-input focus:border-primary-color"
                  value={desc}
                  placeholder={isPackage ? 'Add package details here, including specific dates/times for each session' : 'Description'}
                  rows={3}
                  maxLength={200}
                  id="ccnumber"
                  required
                  onChange={e => {
                    this.onChange('desc', e.target.value)
                  }}
                />
              </CFormGroup>

              <div className="text-left w-full">
                <InputLabel label="Privacy" />
                <Select
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  className="primary-text-regular normal-case"
                  placeholder="Privacy"
                  isSearchable={false}
                  value={privacySetting}
                  onChange={option => this.setState({ privacySetting: option })}
                  options={privacySettings}
                />
              </div>

              <CFormGroup className="mt-2">
                <InputLabel label="Event link" />
                <div className="flex md:items-center flex-col md:flex-row">
                  <span className="font-sf-regular text-sm mr-2 text-primary-color">{`trayvo.com/${currentUser && currentUser.attributes && currentUser.attributes.slug
                    }/`}</span>

                  <Form.Control
                    type="text"
                    className=" font-sf-regular bg-transparent text-black text-xs"
                    onChange={e => {
                      this.setState({ link: e.target.value.toLowerCase() });
                    }}
                    value={removeSpecialCharacters(link)}
                    required
                  />
                </div>
              </CFormGroup>

              <div className="flex flex-row-reverse items-center">
                <button type="submit"
                  disabled={this.state.invalidGrades}
                  className="primary-dark-button my-3 px-3">
                  Add Training Option
                  <Loader
                    visible={buttonLoading}
                    type="ThreeDots"
                    color="white"
                    className="ml-2 self-center"
                    height={10}
                    width={25}
                  />
                </button>

                {onboarding && (
                  <button
                    type="button"
                    onClick={this.props.changeContent}
                    className="self-end px-3 focus:outline-none underline rounded py-2 text-primary-color my-3 hover:text-blue-500 text-xs inline-flex justify-center">
                    Skip
                  </button>
                )}
              </div>
            </div>
          </form>
        </div>

        {
          displayPackageDescriptionReminder && <PackageDescriptionConfirmationModal
            onConfirm={() => this.setState({ displayPackageDescriptionReminder: false })} />
        }

      </div>
    );
  }
}

export default CreateTraining;
