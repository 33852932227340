import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Loader } from '../Loader';
import { formatDate, formatTime } from '../../../utils/utilities';
import { useHistory } from 'react-router-dom';
import { BookingsActions } from '../../../store/actions';

const verticalBorder = { borderLeftWidth: 1, borderColor: '#CCCCCC' };

let connectProps = {
  ...BookingsActions,
};

let connectState = state => ({
  bookings: state.Bookings.booking.get('bookings'),
  loader: state.User.meta.get('showHUD'),
});

let enhancer = connect(connectState, connectProps);

const FilteredBookings = props => {
  let history = useHistory();
  let { bookings, loader, getBookings } = props;

  useEffect(() => {
    getBookings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const moveToDetail = id => {
    history.push({
      pathname: `/bookings/${id}`,
    });
  };

  let filteredBookings = bookings;

  return (
    <div
      className="overflow-scroll bg-white rounded-md mt-2 shadow-md"
      style={{ height: '270px', borderRadius: 10 }}>
      {loader ? (
        <div className="flex items-center justify-center">
          <Loader />
        </div>
      ) : (
        <table className="w-full bg-white gfg">
          <tbody>
            {filteredBookings.length > 0 ? (
              filteredBookings.map((booking) => (
                <tr
                  key={booking.id}
                  className="cursor-pointer border-b border-main-background rt-tr-group"
                  onClick={() => moveToDetail(booking.id)}>
                  <td className="font-sf-regular text-center pl-2 text-gray-700 text-xs border-training-individual rounded-md border-l-2">
                    {formatDate(booking.attributes.booking_date)}
                  </td>

                  <td
                    className="font-sf-regular text-center pl-2 text-primary-color text-xs py-1"
                    style={verticalBorder}>
                    {formatTime(booking.attributes.from_time)}-
                    {formatTime(booking.attributes.to_time)}
                  </td>

                  <td
                    className="font-sf-regular text-center pl-2 text-primary-color text-xs py-1"
                    style={verticalBorder}>
                    {booking.attributes.provided_service.data.attributes.name}
                  </td>

                  <td
                    className="font-sf-regular text-center pl-2 text-primary-color text-xs py-1"
                    style={verticalBorder}>
                    {booking.attributes.client.data.attributes.first_name}{' '}
                    {booking.attributes.client.data.attributes.last_name}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={6}>
                  <div fluid="true" className="flex items-center justify-center p-4">
                    <h5 className="primary-text-regular">No Bookings Available.</h5>
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default enhancer(FilteredBookings);
