import React, { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { UserActions, BookingsActions, CalendarActions, ServiceActions } from '../store/actions';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';
import { Header } from '../components/Athlete/Header';
import { ScrollToTop } from '../components/Shared';

//Public routes
import { App } from '../components/App';
import { NotFound } from '../components/Athlete/NotFound';
import { ForgotPassword } from '../components/Athlete/ForgotPassword';
import { NewPassword } from '../components/Athlete/ForgotPassword';
import {
  TermsService,
  PrivacyPolicy,
  CaliforniaResidentsNotice,
} from '../components/TermsAndPolicy';

//Athlete routes
import { ServiceProfessionalSignin } from '../components/Athlete/SignIn';
import { ServiceProfessionalSignup } from '../components/Athlete/SignUp';
import { Dashboard } from '../components/Athlete/Dashboard';
import PaymentAlertBanner from '../components/Shared/PaymentAlertBanner';
import PendingPaymentBanner from '../components/Shared/PendingPaymentsBanner';
// import { Calendar } from '../components/Athlete/Availability';
// import { Payments, PaymentDetail } from '../components/Athlete/Payments';

const Trainings = lazy(() => import('../components/Athlete/Services/Trainings'));
const TrainingsAdmin = lazy(() => import('../components/Athlete/TrainingsAdmin/TrainingsAdmin'));
const AddCard = lazy(() => import('../components/Athlete/ConnectAccount/AddCard'));
const ProfileEdit = lazy(() => import('../components/Athlete/Profile/ProfileEdit'));
const BookingsIndex = lazy(() => import('../components/Athlete/Bookings/BookingsIndex'));
const BookingsDetail = lazy(() => import('../components/Athlete/Bookings/BookingShow'));
const AdminLogin = lazy(() => import('../components/Admin'))

let connectProps = {
  ...UserActions,
  ...BookingsActions,
  ...ServiceActions,
  ...CalendarActions,
};

let connectState = state => ({
  currentUser: state.User.current.get('currentUser'),
  openMenu: state.User.current.get('openMenu'),
  bookings: state.Bookings.booking.get('bookings')
});

let enhancer = connect(connectState, connectProps);

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

const Routes = props => {
  const userData = props.currentUser && props.currentUser.attributes;
  const auth_token = localStorage.getItem('auth_token');
  const { refreshAthlete, getAvailabilities, getProvidedServices } = props;

  React.useEffect(() => {
    if (auth_token)
      (async () => {
        await refreshAthlete();
        await getAvailabilities();
        await getProvidedServices();
      })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Router>
      <ScrollToTop />
      <React.Suspense fallback={loading}>
        <div className="bg-main-background">
          <div className="c-wrapper">
            {userData && auth_token && <Header />}
            {userData && auth_token && !userData.account_id && (
              <PaymentAlertBanner name={`${userData.first_name} ${userData.last_name}`} />
            )}

            {auth_token && userData?.pending_payments && <PendingPaymentBanner bookings={props.bookings} accountAdded={Boolean(userData.account_id)} />}

            <div className="c-body">
              <Switch>
                <PublicRoute restricted={true} component={App} path="/" exact />

                <PublicRoute
                  component={AdminLogin}
                  path='/admin/login/:token'
                  exact
                />

                <PublicRoute
                  restricted={true}
                  component={ServiceProfessionalSignin}
                  path="/athlete/login"
                  exact
                />

                <PublicRoute
                  restricted={true}
                  component={ServiceProfessionalSignup}
                  path="/athlete/signup"
                  exact
                />

                <PublicRoute
                  restricted={true}
                  component={ForgotPassword}
                  path="/login/reset"
                  exact
                />

                <PublicRoute
                  restricted={true}
                  component={TermsService}
                  path="/terms-of-services"
                  exact
                />

                <PublicRoute
                  restricted={true}
                  component={PrivacyPolicy}
                  path="/privacy-policy"
                  exact
                />

                <PublicRoute
                  restricted={true}
                  component={CaliforniaResidentsNotice}
                  path="/california-residents-notice"
                  exact
                />

                <PublicRoute restricted={true} component={NewPassword} path="/new_password" exact />

                <PrivateRoute component={Dashboard} path="/dashboard" exact />

                {/* <PrivateRoute component={Payments} path="/payments" exact /> */}

                {/* <PrivateRoute component={PaymentDetail} path="/payments/:id" exact /> */}

                {/* <PrivateRoute component={Calendar} path="/availability" exact />

                <PrivateRoute component={Calendar} path="/availability/:month" exact /> */}

                <Suspense fallback={<div className="text-black">Loading...</div>}>
                  <Switch>
                    <PrivateRoute component={BookingsIndex} path="/bookings" exact />
                    <PrivateRoute component={BookingsDetail} path="/bookings/:id" exact />
                    <PrivateRoute component={ProfileEdit} path="/profile/edit" exact />
                    <PrivateRoute component={AddCard} path="/card" exact />
                    <PrivateRoute component={Trainings} path="/trainings" exact />
                    <PrivateRoute component={TrainingsAdmin} path="/admin" exact />
                  </Switch>
                </Suspense>

                <Route component={NotFound} />
              </Switch>
            </div>
          </div>
        </div>
      </React.Suspense>
    </Router>
  );
};

export default enhancer(Routes);
