import React, { Component } from 'react';
import { UserActions, ServiceActions, CalendarActions } from '../../../store/actions';
import { connect } from 'react-redux';
import { TrayvoBookingLogo } from '../../../assets/img';

import { Loader } from '../Loader';
import ReactTooltip from 'react-tooltip';
import {
  isFreeTraining,
  priceFormatter,
  notify,
  borderColor,
  ifTrainingsPublished,
  getTrainingTypes,
  isTrainingLocationPresent,
} from '../../../utils/utilities';
import SettingIcon from '../SettingIcon/SettingIcon';
import { NewTraining, EditTraining } from '../Services';
import { DateSelection, EditAvailability } from '../../Popup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import { ToggleButton, TrainingBadge, ClipboardCopyButton, AvailabilitySet } from '../../Shared';
import { AthleteInfo } from '../Dashboard';
import InfiniteScroll from 'react-infinite-scroller';
import ScrollUpButton from 'react-scroll-up-button';
import CalendarEntries from '../../../hocs/CalendarEntries';
import TrainingOverlay from '../../Shared/TrainingOverlay';
import ConfirmAllPaymentsModal from "../../Shared/ConfirmAllPayments.modal";
import { exportTrainingDataAsCsv } from "../../../store/Bookings/actions";

let connectProps = {
  ...UserActions,
  ...ServiceActions,
  ...CalendarActions,
};

let connectState = state => ({
  providedServices: state.Service.service.get('providedServices'),
  providedServicesPagy: state.Service.service.get('providedServicesPagy'),
  services: state.Service.service.get('services'),
  loader: state.User.meta.get('showHUD'),
  buttonLoading: state.User.meta.get('buttonLoading'),
  currentUser: state.User.current.get('currentUser'),
  selectedTrainingType: state.User.meta.get('selectedTrainingType'),
  selectedTraining: state.Calendar.calendar.get('selectedTraining'),
});

let enhancer = connect(connectState, connectProps);

const initialStates = {
  editVisible: false,
  serviceObj: null,
  duration: 'Min',
  minDuration: '',
  editTrainingPopup: null,

  service: '',
  selectedTrainingId: 0,
  newTrainingPopup: null,
  loadMore: false,
  paymentsModal: {
    show: false,
    trainingId: null
  }
};

class Services extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...initialStates,
    };
  }

  showDetail = id => {
    this.setState({ editTrainingPopup: id });
  };

  loadMore() {
    let { providedServicesPagy, getProvidedServices } = this.props;

    if (providedServicesPagy && providedServicesPagy.next) {
      getProvidedServices(providedServicesPagy.next);
    }
  }

  deleteTraining = async id => {
    await this.props.deleteProvidedService(id);
    notify('Deleted successfully', 'success');
  };

  handleOnToggle = async (id, status) => {
    this.props.changeButtonLoading(true);
    this.setState({ selectedTrainingId: id }, async () => {
      await this.props.updateProvidedService(id, {
        provided_service: {
          status: status === 'published' ? 'unpublished' : 'published',
        }
      })
        .then((data) => {
          if (data?.errors) {
            notify(data?.errors);
          }
        });

      setTimeout(() => { this.props.changeButtonLoading(false) }, 500);
    }
    );
  };

  onDeleteClicked = (e, training) => {
    e.stopPropagation();
    if (training.deleteable) {
      if (window.confirm('Are you sure?')) {
        this.deleteTraining(training.id);
      }

    } else {
      notify('There is a booking associated');
    }
  }

  onOpenConfirmPaymentsModal = (e, training) => {
    e.stopPropagation();
    this.setState({
      paymentsModal: {
        show: true,
        trainingId: training.id
      }
    })
  }

  onExportClicked = (e, training) => {
    e.stopPropagation();
    exportTrainingDataAsCsv(training.name, training.id);
  }

  setNewTrainingPopup = (value = true) => {
    this.setState({ newTrainingPopup: value });
  };

  setAvailabilityPopup = () => {
    let { providedServices, openDateSelection } = this.props;
    ifTrainingsPublished(providedServices) && openDateSelection();
  };

  render() {
    let {
      providedServices,
      providedServicesPagy,
      loader,
      buttonLoading,
      currentUser,
      history,
      selectedTrainingType,
    } = this.props;
    let { selectedTrainingId, editTrainingPopup, newTrainingPopup } = this.state;


    return (
      <div fluid="true" className="bg-main-background">
        <div className="m-1 px-3">
          <AthleteInfo
            currentUser={currentUser}
            history={history}
            createAvailability={this.setAvailabilityPopup}
            createTraining={this.setNewTrainingPopup}
            bookingLinkOnly
            inviteOnly
          />
        </div>

        <div className="col-md-12 items-center justify-center content-center flex">
          <div className="col-md-11">
            <div className="min-h-screen mt-2">
              <div
                className="mt-3 mr-3 ml-3 flex justify-between items-center shadow-md bg-white"
                style={{ borderRadius: 10 }}>
                <small className="font-sf-medium pl-4 text-black py-3" style={{ fontSize: 18 }}>
                  Trainings
                </small>

                <button
                  onClick={this.setNewTrainingPopup}
                  className="rounded-md bg-highlight-color shadow-md px-4 py-2 my-1 mx-3 border-gray-900 border hover:bg-primary-color text-blue-800 hover:text-white">
                  <small className="text-sm font-sf-regular">New Training</small>
                </button>
              </div>

              <div className="flex mb-3 mr-3 ml-3 rounded-md mt-3">
                <InfiniteScroll
                  pageStart={1}
                  className="w-full"
                  loadMore={() => this.loadMore()}
                  hasMore={providedServicesPagy && providedServicesPagy.next}
                  loader={
                    <div className="loader text-black" key={0}>
                      Loading ...
                    </div>
                  }>
                  <div className="w-full flex flex-wrap flex-row md:grid md:grid-cols-4 md:gap-8">
                    {providedServices.length > 0 ? (
                      providedServices
                        .filter(item => !item.attributes.deleted)
                        .map(({ attributes }, index) => {
                          return (
                            <div
                              key={index}
                              className={`flex flex-col items-center p-3 w-full shadow-md mt-2 md:mt-0 bg-white relative border-t-4
                              ${borderColor(attributes.training_type)}`}
                              style={{ height: '360px', borderRadius: 10 }}>
                              {isTrainingLocationPresent(attributes) && (
                                <TrainingOverlay
                                  trainingId={attributes.id}
                                  trainingName={attributes.name}
                                  toggleEditTrainingPopup={this.showDetail}
                                />
                              )}

                              <div className="flex justify-between w-full" style={{ zIndex: 1 }} >
                                <div onClick={e => e.stopPropagation()}>
                                  <ToggleButton
                                    status={attributes.status}
                                    key={attributes.id}
                                    id={attributes.id}
                                    fromTrainings={true}
                                    handleOnToggle={this.handleOnToggle}
                                    training={attributes}
                                  />
                                </div>

                                <div
                                  className={`flex mb-1 items-center justify-center w-full ml-4 ${attributes.status === 'unpublished' && 'opacity-50'
                                    }`}>
                                  <div className="bg-white self-center p-1 rounded-full items-center">
                                    <img
                                      style={{ borderColor: 'white', borderWidth: 4 }}
                                      src={attributes.image_url ? attributes.image_url : TrayvoBookingLogo}
                                      alt=""
                                      className="object-cover shadow-md h-20 w-20 rounded-full border border-white"
                                    />
                                  </div>
                                </div>

                                <SettingIcon>
                                  <div className="flex flex-col" onClick={e => e.stopPropagation()}>
                                    <div className="text-center">
                                      <button
                                        className="text-black hover:text-red-700 py-2 px-4"
                                        onClick={e => this.onDeleteClicked(e, attributes)}>
                                        Delete
                                      </button>
                                    </div>

                                    <div className="text-center">
                                      <button
                                        className="text-black hover:text-blue-500 py-2 px-4"
                                        onClick={e => this.showDetail(attributes.id)}>
                                        Edit
                                      </button>
                                    </div>

                                    {!isFreeTraining(attributes) && <div className="text-center">
                                      <button
                                        className="text-black hover:text-red-700 py-2 px-4"
                                        onClick={e => this.onOpenConfirmPaymentsModal(e, attributes)}>
                                        Payments
                                      </button>
                                    </div>
                                    }
                                    <div className="text-center">
                                      <button
                                        className="text-black hover:text-blue-500 py-2 px-4"
                                        onClick={e => this.onExportClicked(e, attributes)}>
                                        Export
                                      </button>
                                    </div>
                                  </div>
                                </SettingIcon>
                              </div>

                              <div
                                className={`flex flex-col w-full items-center rounded-lg training-card-height ${attributes.status === 'unpublished' && 'opacity-50'
                                  }`}
                                style={{ paddingTop: '10px' }}>
                                <div className="flex items-center leading-tight">
                                  {attributes &&
                                    attributes.privacy_setting &&
                                    attributes.privacy_setting === 'private' && (
                                      <FontAwesomeIcon
                                        icon={faLock}
                                        size="lg"
                                        color="gray"
                                        className="mr-1 p-1"
                                      />
                                    )}
                                  <span className="text-black font-sf-medium capitalize text-sm">
                                    {attributes.name && attributes.name.length > 30
                                      ? `${attributes.name.substring(0, 30)}...`
                                      : attributes.name && attributes.name}
                                  </span>
                                </div>

                                <div
                                  className="text-center max-location-height overflow-hidden"
                                  style={{ paddingTop: '10px' }}>
                                  <small className="font-sf-regular text-gray-900 font-normal text-xs">
                                    {parseInt(attributes && attributes.duration)} mins
                                  </small>

                                  {attributes.training_type !== 'virtual' &&
                                    attributes.provided_service_locations.data &&
                                    attributes.provided_service_locations.data.length > 0 && (
                                      <small className="font-sf-regular text-gray-900 font-normal text-xs text-center capitalize">
                                        <small className="text-black px-1">@</small>
                                        {attributes.provided_service_locations.data
                                          .slice(0, 3)
                                          .map(({ attributes }, index, arr) => {
                                            return (
                                              attributes.location +
                                              `${index < arr.length - 1 ? ' • ' : ''}` +
                                              `${arr.length > 2 && index === arr.length - 1
                                                ? '...'
                                                : ''
                                              }`
                                            );
                                          })}
                                      </small>
                                    )}
                                </div>

                                <small
                                  className="font-sf-medium text-gray-900 capitalize text-sm font-normal self-center"
                                  style={{ paddingTop: '10px' }}>
                                  {isFreeTraining(attributes) ? 'Free' : priceFormatter(attributes.price)}
                                </small>

                                <div style={{ paddingTop: '10px' }}>
                                  <TrainingBadge
                                    trainingType={attributes.training_type}
                                    fullTrainings
                                  />
                                </div>

                                <div style={{ marginTop: '10px' }}>
                                  <AvailabilitySet
                                    {...this.props}
                                    availabilitySet={attributes.availability_set}
                                    training={attributes}
                                    status={attributes.status === 'published'}
                                  />
                                </div>

                                <small
                                  style={{ fontSize: 10, paddingTop: '10px', color: '#7B7B7B' }}
                                  className="text-center font-sf-regular normal-case">
                                  {attributes.discription && attributes.discription.length > 140
                                    ? `${attributes.discription.substring(0, 140)}...`
                                    : attributes.discription}
                                </small>

                                <div className="w-full absolute bottom-0 left-0 p-2">
                                  {!isTrainingLocationPresent(attributes) && (
                                    <ClipboardCopyButton
                                      status={attributes.status}
                                      handleOnToggle={(id, status) =>
                                        this.handleOnToggle(id, status)
                                      }
                                      slug={attributes.slug}
                                      id={attributes.id}
                                      visible={
                                        buttonLoading && attributes.id === selectedTrainingId
                                      }
                                      training={attributes}
                                    />
                                  )}
                                </div>
                              </div>
                            </div>
                          );
                        })
                    ) : (
                      <div fluid="true" className="">
                        {loader ? (
                          <Loader />
                        ) : (
                          <h3 className={'text-center text-sm'}>No Trainings Available</h3>
                        )}
                      </div>
                    )}
                  </div>
                </InfiniteScroll>
              </div>
            </div>
          </div>

          <ReactTooltip />
        </div>

        {editTrainingPopup && (
          <EditTraining
            serviceId={editTrainingPopup}
            isVisible={editTrainingPopup}
            closePopup={choice => {
              this.setState({ editTrainingPopup: null });
              if (choice && choice.showAvailabilityPopup) this.setAvailabilityPopup();
            }}
          />
        )}

        {this.props.dateSelectionModal && (
          <DateSelection
            {...this.props}
            closeModal={() => this.props.closeDateSelection()}
            providedServices={getTrainingTypes(providedServices)}
            trainings={providedServices}
            trainingsPagy={providedServicesPagy}
            getBulkTrainings={() => { }}
            selectedTrainingType={selectedTrainingType}
          />
        )}

        {newTrainingPopup && (
          <NewTraining
            {...this.props}
            closePopup={choice => {
              this.setNewTrainingPopup(null);
              if (choice && choice.showAvailabilityPopup) this.setAvailabilityPopup();
            }}
          />
        )}

        {this.props.editAvailability && (
          <EditAvailability
            isVisible
            refreshModal={this.props.refreshModal}
            availId={this.props.availId}
            updateFrom={this.props.updateFrom}
            updateTo={this.props.updateTo}
            updateRangeFrom={this.props.updateRangeFrom}
            updateRangeTo={this.props.updateRangeTo}
            optionType={this.props.optionType}
            repCount={this.props.repCount}
            closeModal={() => this.props.closeDateSelection()}
            buttonLoading={this.props.buttonLoading}
            apply={this.props.applyDateSelection}
            days={this.props.days}
            trainings={providedServices}
            trainingsPagy={providedServicesPagy}
            training_type={this.props.training_type}
            selected_trainings={this.props.selected_trainings}
            training_color={this.props.training_color}
            providedServices={getTrainingTypes(providedServices)}
            getBulkTrainings={() => { }}
          />
        )}

        <ScrollUpButton />

        {
          this.state.paymentsModal.show && <ConfirmAllPaymentsModal
            key={this.state.paymentsModal.trainingId}
            trainingId={this.state.paymentsModal.trainingId}
            onHide={() => this.setState({ paymentsModal: { show: false } })} />
        }

      </div>
    );
  }
}

export default enhancer(CalendarEntries(Services));
