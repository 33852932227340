const api = {
  //=============================Athlete=============================
  signUp: () => `v1/athlete/profiles`,
  signIn: () => `v1/athlete/sessions`,
  viewProfile: () => `v1/athlete/profiles`,

  //bookings
  bookings: () => `v1/athlete/bookings`,

  //bookingRequests
  requests: () => `v1/athlete/booking_requests`,

  // Confirm All Payments/bookings
  awaitingPayments: (trainingId) => 'v2/athlete/payments/awaiting_payments?training_id=' + trainingId,
  confirmAllPayments: 'v2/athlete/payments/confirm_all',

  // Export All emails from bookings of a trainings
  exportEmails: (trainingId) => 'v2/athlete/export/emails?training_id=' + trainingId,

  //availabilities
  availabilities: () => `v1/athlete/availabilities`,
  entries: () => `v1/athlete/calendar_entries`,
  calendarEntriesCount: () => 'v1/athlete/calendar_entries/calendar_entries_count',
  updateEntry: () => `v1/athlete/calendar_entries`,
  trainingAvailabilities: () => `v1/athlete/availabilities/provided_service_availabilities`,

  //connect-account
  account: () => `v1/athlete/profiles/connect_account`,
  payments: () => `v1/athlete/payments`,
  transferCharge: id => `v1/payments/${id}/transfer_charge/`,

  //services
  provided: () => `v1/athlete/provided_services`,
  trainingSignedUrl: () => `v1/athlete/provided_services/signed_url?file_type=jpg`,
  service: () => `v1/athlete/services`,

  profileSignedUrl: () => `v1/athlete/profiles/signed_url?file_type=jpg`,
  videoSignedUrl: extension => `v1/athlete/profiles/signed_url?file_type=${extension}`,

  //check if email or phone is valid
  checkValid: () => 'v1/athlete/profiles/check_email_or_phone',

  //resetpassword
  sendResetPassword: () => `v1/athlete/profiles/send_reset_password`,
  updatepassword: () => `v1/athlete/profiles/update_password`,
  resetPassword: () => `v1/athlete/profiles/reset_password`,

  getSchools: () => `v1/high_schools`,
  getColleges: () => `v1/colleges`,
  getGroups: () => `v2/athlete/groups`,
  getSports: () => `v1/sports`,

  //referral code validation
  referralCode: code => `v1/athlete/profiles/check_valid_referral_code?referral_code=${code}`,
};

export default api;
