import React, { useState, useEffect, createRef } from 'react';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { TopTabs } from '../TopTabs';
import { UserActions } from '../../../store/actions';
import { getAthleteImageSrc } from '../../../utils/utilities';
import { Logo } from '../../../assets/img';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell } from '@fortawesome/free-solid-svg-icons';
import ReactTooltip from 'react-tooltip';
import { pendingPayments } from '../../../stringConstants';

let connectProps = {
  ...UserActions,
};

let connectState = state => ({
  openMenu: state.User.current.get('openMenu'),
  currentUser: state.User.current.get('currentUser'),
});

let enhancer = connect(connectState, connectProps);

function Header(props) {
  const [expanded, setExpanded] = useState(false)
  const history = useHistory();
  const navbarRef = createRef()

  useEffect(() => {
    if (!expanded) return;

    function handleNavClose(e) {
      const navbarHeight = navbarRef.current.clientHeight;
      const mouseY = e.clientY;
      if (mouseY < navbarHeight) return handleExpanded();
    }

    document.addEventListener('click', handleNavClose);
    return () => document.removeEventListener('click', handleNavClose);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expanded]);

  const logout = () => {
    props.logoutPressed();
    localStorage.clear();
    history.push('/');
  };

  const handleExpanded = () => {
    setExpanded(!expanded)
  }

  // let openMenu = props.openMenu;
  let currentUser = props.currentUser;
  let pendingPayment = props.currentUser && props.currentUser.attributes.pending_payments;

  return (
    <div className="flex w-full">
      <Navbar collapseOnSelect={true} expanded={expanded} expand="lg" className="bg-primary-color w-full" name='navbar' ref={navbarRef} >
        <Navbar.Brand href="/dashboard" className="font-sf-bold mx-3 text-white">
          <div className="flex items-center">
            <img src={Logo} alt="" className="w-20 h-10 object-cover" />
          </div>
        </Navbar.Brand>

        <Navbar.Toggle
          onClick={handleExpanded}
          aria-controls="basic-navbar-nav"
          className="flex justify-end items-end self-end"
        />

        <Navbar.Collapse>
          <Nav className="md:mx-auto w-1/2 xl:w-1/3">
            <TopTabs closeNav={() => setExpanded(false)} />
          </Nav>

          <Nav className="ml-3">
            <div className="relative md:self-center self-start md:mr-3 ml-1 md:ml-0">
              {pendingPayment && (
                <div className="h-3 w-3 rounded-full bg-red-600 absolute right-0" />
              )}
              <FontAwesomeIcon
                id='bell-icon-svg'
                icon={faBell}
                size="lg"
                className={`self-center mr-2 ${pendingPayment && 'notification'}`}
                data-tip={pendingPayment ? pendingPayments : 'No pending payments'}
                data-for="pendingPayments"
              />
            </div>

            <NavDropdown
              flip="true"
              title={
                <div
                  className="border-white border-2 h-10 w-10 rounded-full object-center mr-2 overflow-hidden"
                  style={{
                    backgroundColor:
                      currentUser &&
                      currentUser.attributes &&
                      currentUser.attributes.college &&
                      currentUser.attributes.college.color_code,
                  }}>
                  <img
                    className="object-cover rounded-full h-full w-full"
                    alt=""
                    src={getAthleteImageSrc(currentUser && currentUser.attributes)}
                  />
                </div>
              }>
              <NavDropdown.Item className="font-sf-regular" href={'/profile/edit'}>
                Profile
              </NavDropdown.Item>

              <NavDropdown.Divider />

              <NavDropdown.Item className="font-sf-regular" href="/card">
                Bank Account
              </NavDropdown.Item>

              <NavDropdown.Divider />

              <NavDropdown.Item className="font-sf-regular" onClick={() => logout()}>
                Logout
              </NavDropdown.Item>

            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Navbar>

      <ReactTooltip
        id={'pendingPayments'}
        type="success"
        effect="solid"
        multiline={true}
        border
        textColor="#fff"
        place="bottom"
        className="max-w-xs"
        backgroundColor="#042E60"
      />
    </div>
  );
}

export default enhancer(Header);
